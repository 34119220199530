import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, Article, SEO } from '../components'

class SparePartsPage extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const { data } = this.props

    this.state = {
      spareParts: data.allMysqlSpareparts.nodes.slice(0, 5),
      skip: 0,
      totalSpareParts: data.allMysqlSpareparts.totalCount,
      limit: 5,
    }
  }

  getNextSpareParts = () => {
    const { totalSpareParts } = this.state
    let { skip, limit } = this.state

    if (skip < totalSpareParts) {
      skip += 5
      limit += 5

      const {
        data: {
          allMysqlSpareparts: { nodes },
        },
      } = this.props

      this.setState({
        spareParts: nodes.slice(skip, limit),
        skip,
        limit,
      })

      window.scrollTo(0, 0)
    }
  }

  getPreviousSpareParts = () => {
    let { skip, limit } = this.state

    if (skip > 0) {
      skip -= 5
      limit -= 5

      const {
        data: {
          allMysqlSpareparts: { nodes },
        },
      } = this.props

      this.setState({
        spareParts: nodes.slice(skip, limit),
        skip,
        limit,
      })

      window.scrollTo(0, 0)
    }
  }

  render() {
    const { spareParts, skip, totalSpareParts, limit } = this.state
    const { data } = this.props

    return (
      <Layout>
        <SEO
          title="Pièces détachées Hörmann"
          description="Nous vendons également des pièces détachées pour les portes de garage sectionnelle et basculante avec ou sans portillon de la marque Hörmann"
        />
        <h1 className="title">Pièces détachées</h1>
        <div className="lg:hidden">
          {data &&
            spareParts &&
            spareParts.map(
              (
                {
                  description,
                  info1,
                  info2,
                  info3,
                  info4,
                  numero,
                  numerohormann,
                  picture,
                  fields: { pdf },
                },
                index
              ) => (
                <a
                  key={index}
                  href={pdf}
                  download={`ps-pièce détachée_${numerohormann}.pdf`}
                >
                  <Article
                    title={`${numerohormann} ${numero}`}
                    image={
                      <img
                        src={`${process.env.SITE}/${picture}`}
                        alt={`pièce détachée ${numerohormann}`}
                      />
                    }
                    description={description}
                    tags={[info1, info2, info3, info4]}
                  />
                </a>
              )
            )}
        </div>
        <div className="hidden lg:block bg-white shadow-md rounded my-6">
          <table className="text-left w-full border-collapse">
            <thead>
              <tr>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light">
                  N° pièce
                </th>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light">
                  Matériel
                </th>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light">
                  Description
                </th>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light">
                  Image
                </th>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light">
                  Stock
                </th>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light">
                  Détails / Informations complémentaires
                </th>
                <th className="py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-black border-b border-gray-light" />
              </tr>
            </thead>
            <tbody>
              {data &&
                spareParts &&
                spareParts.map(
                  (
                    {
                      description,
                      info1,
                      info2,
                      info3,
                      info4,
                      numero,
                      numerohormann,
                      picture,
                      fields: { pdf },
                    },
                    index
                  ) => (
                    <tr key={index} className="hover:bg-gray-300">
                      <td className="py-4 px-6 border-b border-gray-400">
                        {numerohormann}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-400">
                        {numero}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-400">
                        {description}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-400">
                        <img
                          src={`${process.env.SITE}/${picture}`}
                          alt={`pièce détachée ${numerohormann}`}
                        />
                      </td>
                      <td className="py-4 px-6 border-b border-gray-400">
                        {info1}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-400">
                        {info2}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-400">
                        <a
                          key={index}
                          className="btn btn-blue"
                          href={pdf}
                          download={`ps-pièce détachée_${numerohormann}.pdf`}
                        >
                          <i className="fas fa-print" />
                        </a>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-between">
          {skip > 0 ? (
            <button
              type="button"
              className="btn btn-red"
              onClick={this.getPreviousSpareParts}
            >
              Précédent
            </button>
          ) : (
            <div />
          )}
          <p className="self-center p-0 font-bold text-lg tracking-wider">
            {limit / 5} / {Math.round(totalSpareParts / 5)}
          </p>
          {limit < totalSpareParts ? (
            <button
              type="button"
              className="btn btn-green"
              onClick={this.getNextSpareParts}
            >
              Suivant
            </button>
          ) : (
            <div />
          )}
        </div>
      </Layout>
    )
  }
}

export default SparePartsPage

export const pageQuery = graphql`
  query SpareParts {
    allMysqlSpareparts {
      nodes {
        description
        info1
        info2
        info3
        info4
        numero
        numerohormann
        picture
        fields {
          pdf
        }
      }
      totalCount
    }
    file(relativeDirectory: { eq: "" }, name: { eq: "logo-ps" }) {
      logo: childImageSharp {
        original {
          src
        }
      }
    }
  }
`
